
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.schedule-display {
    .day {
        border: solid 1px #000;
        width: 150px;
        height: 200px;
        float: left;
        margin: 10px 5px;
        padding: 2px;
        overflow: hidden;
    }
    [type=checkbox] {
        display: none;
    }
}

@media only screen and (max-width: 800px) {
    .flip-scroll.cf:after,
    .flip-scroll .cf:after {
        visibility: hidden;
        display: block;
        font-size: 0;
        content: " ";
        clear: both;
        height: 0;
    }
    .flip-scroll * html .cf {
        zoom: 1;
    }
    .flip-scroll *:first-child+html .cf {
        zoom: 1;
    }
    .flip-scroll table {
        display: block;
        position: relative;
        width: 100%;
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
    }
    .flip-scroll th,
    .flip-scroll td {
        margin: 0;
        vertical-align: top;
    }
    .flip-scroll th {
        text-align: left;
    }
    .flip-scroll thead {
        display: block;
        float: left;
    }
    .flip-scroll tbody {
        display: block;
        width: auto;
        position: relative;
        overflow-x: auto;
        white-space: nowrap;
    }
    .flip-scroll thead tr {
        display: block;
    }
    .flip-scroll th {
        display: block;
        text-align: right;
    }
    .flip-scroll tbody tr {
        display: inline-block;
        vertical-align: top;
    }
    .flip-scroll td {
        display: block;
        min-height: 1.25em;
        text-align: left;
    }
    /* sort out borders */

    .flip-scroll th {
        border-bottom: 0;
        border-left: 0;
    }
    #app .flip-scroll.table th {
        border-top: 1px solid #ddd;
        border-bottom: 0;
    }
    .flip-scroll td {
        border-left: 0;
        border-right: 0;
        border-bottom: 0;
    }
    .flip-scroll tbody tr {
        border-left: 1px solid #babcbf;
    }
    #app .flip-scroll.table th:last-child,
    .flip-scroll th:last-child,
    .flip-scroll td:last-child {
        border-bottom: 1px solid #babcbf;
    }
}

.clickable {
    cursor: pointer;
}

a[data-toggle=collapse] {
    text-decoration: none;
}

[data-toggle=collapse] .glyphicon-plus,
[data-toggle=collapse].collapsed .glyphicon-minus {
    display: none;
}

[data-toggle=collapse].collapsed .glyphicon-plus {
    display: inline;
}

.submenu {
    margin-top: -23px;
    border-radius: 0;
    background-color: #eee;
}

.page-title {
    margin-top: -10px;
}

.submenu .navbar-right {
    float: right;
}

.submenu .navbar-nav li {
    float: left;
}
